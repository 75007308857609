


export default function Rights(){
    return <>
        <div className="rights">
            <div className ="rights-border" ></div>
            <h1 className = "rights-title" >جميع حقوقك محفوظه</h1>
            <h5 className ="rights-text">
            عند شراءك أو عرض عقارك معنا يتم كتابة عقد لحفظ جميع حقوقك وحقوقنا
            <br/>
            <span>يَا أَيُّهَا الَّذِينَ آمَنُوا أَوْفُوا بِالْعُقُودِ</span>
            </h5>
        </div>
    </>
}