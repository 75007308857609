

export default function Nav(){
    return (
        <div  className="nav">
            <div className="nav-background" >
                <img className="main-image" src="./images/main.webp"></img>
            </div>
            {window.innerWidth >= 900 ? 
            <NavBar></NavBar> :
            <NavBarMobile></NavBarMobile>
            }
        </div>
    )
}   


export function  WorkWithUs(){
    return <a href = "https://wa.me/+9660591488501">
            <li className="nav-btn nav-link">أعمل معنا<i class="fa-solid fa-arrow-left"></i></li>
           </a>
}

export function NavBar(){ 
    return             <nav className="nav-bar" >
    <img className="main-logo" src="./images/logo.png"></img>
    <ul className="nav-links">
        <a  href="https://www.snapchat.com/add/wsytaqry6865?share_id=HWYZixw3bRE&locale=ar-SA"><li className="nav-link" >سناب شات</li></a>
       <a href ="https://wa.me/+9660591488501" ><li className="nav-link" >واتساب</li></a>
       <a herf = "https://x.com/wsee6_____?t=2eeTzwDxL2a64N86O7qHzg&s=09" ><li className="nav-link" >تويتر</li></a>
        <WorkWithUs></WorkWithUs>
    </ul>
</nav>
}


export function NavBarMobile(){
    return <div className = "nav-bar-small">
        <img className="main-logo-small" src="./images/logo.png"></img>
        <a href="https://wa.me/+9660591488501"><i id="nav-btn" class="fa-brands fa-whatsapp"></i></a>
    </div>
}