
import Nav from "./nav"
import Feture from "./fetur"
import Sale from "./sale.js"
import Rights from "./rights.js"
import Footer from "./footer.js"
export default function Main(){
    return (
        <div className="main">
            <Nav></Nav>
            <Sale></Sale>
            <Feture></Feture>
            <Rights></Rights>
            <Footer></Footer>
        </div>
    )
}

