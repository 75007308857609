import { WorkWithUs } from "./nav"

export default function Feture(){
    return <>
        <div className="features" > 
            <img className="features-image" src="./images/feature.png"></img>
            <div className = "features-content" >
                <p className="features-content-tag"> مسؤليتنا هي حمايتك</p>
                <div className="border-text"></div>
                <h1 className="features-content-title">أنت في أيدي أمنة</h1>
                <WorkWithUs></WorkWithUs>
            </div>
        </div>
    </>
}