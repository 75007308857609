

import { WorkWithUs } from "./nav"
export default function Footer(){
    return <>
    <footer>
    <div className = "footer-content">
        <h1 className ="footer-title" >
        <span> رقم رخصة فال : </span>
        ١١٠٠٢٥٣٩٦٣
        </h1>
        <WorkWithUs></WorkWithUs>
    </div>  
    <div className = "footer-links">
        <img className ="footer-logo" src="./images/logo.png"></img>
        <div className ="footer-link">
        <a className = "footer-btn" style = {{color : "#FFF"}} href = "https://x.com/wsee6_____?t=2eeTzwDxL2a64N86O7qHzg&s=09"><i class="fa-brands fa-twitter"></i></a>
        <a className = "footer-btn" style = {{color : "#FFF"}} href = "https://www.snapchat.com/add/wsytaqry6865?share_id=HWYZixw3bRE&locale=ar-SA"><i class="fa-brands fa-snapchat"></i></a>
        <a className = "footer-btn" style = {{color : "#FFF"}} href = "https://wa.me/+9660591488501"><i class="fa-brands fa-whatsapp"></i></a>
        </div>
    </div>  
    </footer>    
</>
}